<script setup>

</script>

<template>
<SlideControl>
  <SlideItem><SkeletonCarTile /></SlideItem>
  <SlideItem><SkeletonCarTile /></SlideItem>
  <SlideItem><SkeletonCarTile /></SlideItem>
  <SlideItem><SkeletonCarTile /></SlideItem>
</SlideControl>
</template>
